@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  container: summary / inline-size;
}

.container {
  display: flex;
  gap: pxToRem(16);
  flex-direction: column;
}

.summary {
  display: flex;
  gap: pxToRem(8);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: pxToRem(16);
  border: $border-0;
  border-radius: var(--cor_rad-lg);
}

.imageContainer {
  width: pxToRem(114);
  height: pxToRem(24);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

.content {
  @include fontSize(15, 20);
  color: var(--tex-def-tie1);
  display: flex;
  gap: pxToRem(8);
  align-items: center;
  flex-direction: column;
}

.ratingInfoContainer {
  display: flex;
  gap: pxToRem(8);
}

.button {
  :global {
    .MuiButton-label {
      @include fontSize(17, 24);
      font-weight: 800;
      color: $color-uiPrimary-600;
      white-space: nowrap;
    }
  }
}

@container summary (min-width: #{pxToRem(420)}) {
  .content {
    flex-direction: row;
  }
}

@container summary (min-width: #{pxToRem(500)}) {
  .summary {
    flex-direction: row;
    gap: pxToRem(32);
    width: 100%;
  }
}

@container summary (min-width: #{pxToRem(920)}) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    gap: pxToRem(32);
  }
}
